export const SET_USER_SESSION_DATA = "USER_SET_SESSION_DATA";


export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_SESSION_DATA,
      data: user
    });
  };
}

