import { lazy } from "react";

const Signup = lazy(() => import("./Signup"));

// const Signin = lazy(() => import("./Signin"));

const Signin = lazy(() => import("action-labor/components/authentication/Signin"));

const ForgotPassword = lazy(() => import("action-labor/components/authentication/ForgotPassword"));



const sessionsRoutes = [
  {
    path: "/signup",
    component: Signup
  },
  {
    path: "/signin/:email?",
    component: Signin
  },
  {
    path: "/forgot-password/:email?",
    component: ForgotPassword
  },
];

export default sessionsRoutes;
