import axios from 'axios'
import moment from 'moment'
import { Store } from 'app/redux/Store';
import config from 'action-labor/config';
import { endpoints } from 'action-labor/endpoints';

export const session_token = async (url, email, password) => {
    return new Promise(async (resolve, reject) => {
        try {
            const session_user = await axios.post(url, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            Store.dispatch({
                type: 'USER_SET_SESSION_DATA',
                data: session_user.data
            })
            resolve(session_user)
        } catch (error) {
            reject(error)
        }
    })
}



export const remaining_session_token_expiry_time = async () => {
    const token = Store.getState().dreamFactory.session_token;
    const { exp } = parseJwt(token);
    const { remoteConfig } = Store.getState();
    return remainingTime(exp) >= config.remainingTime ? remainingTime(exp) : await session_token(
        remoteConfig.session_base_url + endpoints.session_token,
        remoteConfig.user_email,
        remoteConfig.password
    );
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const remainingTime = exp => {
    const currentUnixTime = Math.round(new Date().getTime() / 1000);
    const duration = moment.duration(
        moment.unix(exp).diff(moment.unix(currentUnixTime))
    );
    const mins = duration.asMinutes();
    return Math.round(mins);
};


