import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
// import jwtAuthService from "../services/jwtAuthService";
// import localStorageService from "../services/localStorageService";
// import firebaseAuthService from "../services/firebase/firebaseAuthService";

class Auth extends Component {
  state = {};
  
  // checkJwtAuth = () => {
  //   jwtAuthService.loginWithToken().then(user => {
  //     console.log('user', user);
  //     this.props.setUserData(user);
  //   });
  // };

  // checkFirebaseAuth = async () => {
  //   await firebaseAuthService.checkAuthStatus(user => {
  //     if (user) {
  //       this.props.setUserData(user);
  //       // console.log(user.uid);
  //       // console.log(user.email);
  //       // console.log(user.emailVerified);
  //     } else {
  //       console.log("not logged in");
  //     }
  //   });
  // };

  render() {
    const { children } = this.props;
    return (
      <Fragment>{children}</Fragment>
    );
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(mapStateToProps, { setUserData })(Auth);
