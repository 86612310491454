
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/remote-config";
import firebaseConfig from "app/services/firebase/firebaseConfig";
import config from "action-labor/config";



export const firebaseinitializer = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
    }
    return;
}

export const currentUser = async () => {
    onAuthStateChanged();
    return await firebase.auth().currentUser;
}

export const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
};

export const onAuthStateChanged = () => {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                // console.log('user', user);
                resolve(user);
            } else {
                // No user is signed in.
                console.log('No user is signed in',);
                resolve(null);
            }
        });
    })

}

export const signOut = () => {
    return firebase.auth().signOut();
};

export const remoteConfigValue = (key) => {
    return new Promise((resolve, reject) => {
        const remoteConfig = firebase.remoteConfig();
        remoteConfig
            .fetchAndActivate()
            .then(activated => {
                if (!activated) console.log('not activated');
                return remoteConfig.getAll()
            })
            .then(remoteFlags => {
                const config = {};
                for (const [key, value] of Object.entries(remoteFlags)) {
                    config[key] = value._value
                }
                resolve(config);
            })
            .catch(err => {
                reject(err);
            })
    })

}

export const resetPassword = (email) => {
    return firebase.auth().sendPasswordResetEmail(email, { url: config.reset_link_url })
}

export const createUser = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
}


