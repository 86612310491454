import React from "react";
import { Redirect } from "react-router-dom";
import { lazy } from "react";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import mainRoutes from 'action-labor/Routes';
import SessionGuard from "./auth/SessionGuard";


// import dashboardRoutes from "./views/dashboard/dashboardRoutes";
// import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
import formsRoutes from "./views/forms/formsRoutes";

// import widgetsRoute from "./views/widgets/widgetsRoute";
// import chartsRoute from "./views/charts/chartsRoute";
// import dataTableRoute from "./views/dataTable/dataTableRoute";
// import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
// import pagesRoutes from "./views/pages/pagesRoutes";
// import iconsRoutes from "./views/icons/iconsRoutes";
// import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
// import inboxRoutes from "./views/app/inbox/inboxRoutes";
// import chatRoutes from "./views/app/chat/chatRoutes";
// import calendarRoutes from "./views/app/calendar/calendarRoutes";
// import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
// import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
// import contactRoutes from "./views/app/contact/contactRoutes";
const Support = lazy(() => import("../action-labor/components/Support"));
const Privacy = lazy(() => import("../action-labor/components/Privacy"));
const Terms = lazy(() => import("../action-labor/components/Terms"));

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/jobs" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/404" />
  }
];

const Error404 = lazy(() => import("app/views/sessions/Error"));

const routes = [
  {
    path: "/users",
    component: SessionGuard,
    routes: [
      ...sessionsRoutes
    ]
  },
  {
    path: "/404",
    component: Error404
  },
  {
    path: "/support",
    component: Support
  },
  {
    path: "/terms",
    component: Terms
  },
  {
    path: "/privacy",
    component: Privacy
  },
  {
    path: "/",
    component: AuthGuard,
    routes: [
      // ...dashboardRoutes,
      // ...uiKitsRoutes,
      ...formsRoutes,
      // ...widgetsRoute,
      // ...chartsRoute,
      // ...dataTableRoute,
      // ...extraKitsRoutes,
      // ...pagesRoutes,
      // ...iconsRoutes,
      // ...invoiceRoutes,
      // ...inboxRoutes,
      // ...chatRoutes,
      // ...taskManagerRoutes,
      // ...calendarRoutes,
      // ...ecommerceRoutes,
      // ...contactRoutes,
      ...mainRoutes,
      ...redirectRoute,
      ...errorRoute,

    ]
  }
];

export default routes;
