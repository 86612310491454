import "../fake-db";
import React, { Suspense, useState, useEffect } from "react";
import "../styles/app/app.scss";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import { initializer } from 'action-labor/services/appInitializer';

const App = () => {
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const fetchInitializer = async () => {
      await initializer();
      setLoading(false);
    }
    fetchInitializer();
  }, [])

  useEffect(() => {
    !loading && googlePlacesApiScript();
  }, [loading])

  const googlePlacesApiScript = () => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${Store.getState().remoteConfig.maps_api_key}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
  }

  return (
    loading ? <Loading /> :
      <AppContext.Provider value={{ routes }}>
        <Provider store={Store}>
          <Auth>
            <Suspense fallback={<Loading></Loading>}>
              <Router history={history}>{renderRoutes(RootRoutes)}</Router>
            </Suspense>
          </Auth>
        </Provider>
      </AppContext.Provider>
  );
}

export default App;
