const dev = {
  apiKey: "AIzaSyB1US-uIEQ_5Q6FmdVdqOgFO5dp1QHAQ38",
  authDomain: "actionlabor.firebaseapp.com",
  projectId: "actionlabor",
  storageBucket: "actionlabor.appspot.com",
  messagingSenderId: "68396192422",
  appId: "1:68396192422:web:e5654cac63a96f51b09a67",
  measurementId: "G-09EQMZWQ4W",
};

const uat = {
  apiKey: "AIzaSyDFQEJ1r83mTYSs-lxuHwWZzb4Q3jaE34w",
  authDomain: "action-labor-uat.firebaseapp.com",
  projectId: "action-labor-uat",
  storageBucket: "action-labor-uat.appspot.com",
  messagingSenderId: "210041573490",
  appId: "1:210041573490:web:04a15ff30eb5c9cc9372eb",
  measurementId: "G-3TXBTLE7S2",
};

const prod = {
  apiKey: "AIzaSyAgemoC3uBe4w66iHvD5cr2kcl5-rVeOmk",
  authDomain: "awesome-wares-323818.firebaseapp.com",
  projectId: "awesome-wares-323818",
  storageBucket: "awesome-wares-323818.appspot.com",
  messagingSenderId: "420747782500",
  appId: "1:420747782500:web:5af999eeaf218422ac7271",
  measurementId: "G-S5CVSBRZB8",
};

const firebaseConfig =
  process.env.REACT_APP_STAGE === "dev"
    ? dev
    : process.env.REACT_APP_STAGE === "uat"
    ? uat
    : process.env.REACT_APP_STAGE === "prod"
    ? prod
    : dev;

// eslint-disable-next-line
export default {
  ...firebaseConfig,
};
