import axios from 'axios';
import { endpoints } from 'action-labor/endpoints';

export const fetch_user_details = async (uid, api_base_url, api_key, session_token) => {
    const url = api_base_url + endpoints.user_details + `"${uid}"`;
    return await (await axios.get(
        url,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-DreamFactory-API-Key': api_key,
                'X-DreamFactory-Session-Token': session_token
            }
        })).data.resource
}
