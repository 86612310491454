import React, { Component, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";
import { Redirect } from "react-router";

class SessionGuard extends Component {
    constructor(props, context) {
        super(props);
        let { routes } = context;

        this.state = {
            authenticated: true,
            routes
        };
    }

    render() {
        const { route } = this.props;
        const { user } = this.props;
        return (
            Object.keys(user).length ? <Redirect to={{ pathname: '/dashboard' }} /> :
                <Fragment>
                    {
                        route.routes.map((res) =>
                            <Route key={res.path} {...res} path={route.path + res.path}></Route>
                        )
                    }
                </Fragment>
        );
    }
}

SessionGuard.contextType = AppContext;

const mapStateToProps = state => ({
    user: state.user
});

export default withRouter(connect(mapStateToProps)(SessionGuard));
