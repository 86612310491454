// import jwtAuthService from "../../services/jwtAuthService";
// import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "@history.js";

import { signInWithEmailAndPassword } from 'action-labor/services/firebaseAuthService';
import { fetch_user_details } from 'action-labor/services/userDetails';
import { Store } from 'app/redux/Store';

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

export function loginWithEmailAndPassword({ email, password }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    // jwtAuthService
    //   .loginWithEmailAndPassword(email, password)
    //   .then(user => {
    //     dispatch(setUserData(user));

    //     history.push({
    //       pathname: "/"
    //     });

    //     return dispatch({
    //       type: LOGIN_SUCCESS
    //     });
    //   })
    //   .catch(error => {
    //     return dispatch({
    //       type: LOGIN_ERROR,
    //       payload: error
    //     });
    //   });
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}

export function reset_error() {
  return dispatch => {
    dispatch({
      type: "RESET_ERROR"
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {

    dispatch({
      type: LOGIN_LOADING
    });

    signInWithEmailAndPassword(email, password)
      .then(async user => {
        if (user) {
          const { remoteConfig, dreamFactory } = Store.getState();
          const user_details = await fetch_user_details(
            user.user.uid,
            remoteConfig.api_base_url,
            remoteConfig.api_key,
            dreamFactory.session_token
          );
          dispatch(
            setUserData({
              ...user.user,
              ...user_details[0],
              role: user_details[0].office_location_id === 1 ? "SA" : "ADMIN"
            })
          );

          history.push({
            pathname: "/"
          });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        console.log('err', error);
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}
