import { firebaseinitializer, onAuthStateChanged, remoteConfigValue } from './firebaseAuthService';
import { Store } from 'app/redux/Store';
import { session_token } from './dreamFactory';
import { endpoints } from '../endpoints';
import { fetch_user_details } from 'action-labor/services/userDetails';


export const initializer = () => {
    return new Promise(async (resolve, reject) => {
        try {
            await firebaseinitializer();
            const remoteConfig = await remoteConfigValue();
            Store.dispatch({
                type: 'SET_REMOTE_CONFIG',
                data: {
                    ...remoteConfig
                }
            })
            await session_token(
                remoteConfig.session_base_url + endpoints.session_token,
                remoteConfig.user_email,
                remoteConfig.password
            );
            const user = await onAuthStateChanged();
            if (user) {
                const { remoteConfig, dreamFactory } = Store.getState();
                const user_details = await fetch_user_details(user.uid, remoteConfig.api_base_url, remoteConfig.api_key, dreamFactory.session_token);
                Store.dispatch({
                    type: 'USER_SET_DATA',
                    data: {
                        ...user,
                        ...user_details[0],
                        role: user_details[0].office_location_id === 1 ? "SA" : "ADMIN",
                    }
                })
            }
            resolve()
        } catch (error) {
            console.log('error', error);
            reject(error)
        }
    })

}
