const initialState = {
    user_email: null,
    password: null,
    api_base_url: null,
    api_key: null,
    session_base_url: null,
    candidate_age_bar: null,
    notification_img_url: null,
    maps_api_key: null,
    fcm_server_key: null
};

const remoteConfigReducer = function (state = initialState, action) {
    switch (action.type) {
        case "SET_REMOTE_CONFIG": {
            return {
                ...state,
                ...action.data
            };
        }
        default: {
            return state;
        }
    }
};

export default remoteConfigReducer;
