// REACT_APP_STAGE=prod npm run build

const dev = {
  remainingTime: 5,
  reset_link_url: "https://dev-actionlabor.firebaseapp.com/users/signin",
  EMAILJS_SERVICE_ID: "service_uefvrt1",
  EMAILJS_USER_ID: "user_wjClkVgUNRtdMHqGBEzEs",
  EMAILJS_CONTACT_TEMPLATE_ID: "template_xrgnn9j",
};

// eslint-disable-next-line no-unused-vars
const qa = {
  remainingTime: 5,
};

// eslint-disable-next-line no-unused-vars
const uat = {
  remainingTime: 5,
  reset_link_url: "https://uat-al.digitalavenues.net/users/signin",
  EMAILJS_SERVICE_ID: "service_uefvrt1",
  EMAILJS_USER_ID: "user_wjClkVgUNRtdMHqGBEzEs",
  EMAILJS_CONTACT_TEMPLATE_ID: "template_xrgnn9j",
};

const prod = {
  remainingTime: 5,
  reset_link_url: "https://jobs.actionlabor.com/users/signin",
  EMAILJS_SERVICE_ID: "service_sima5d8",
  EMAILJS_USER_ID: "user_wjClkVgUNRtdMHqGBEzEs",
  EMAILJS_CONTACT_TEMPLATE_ID: "template_xrgnn9j",
};

const config =
  process.env.REACT_APP_STAGE === "dev"
    ? dev
    : process.env.REACT_APP_STAGE === "uat"
    ? uat
    : process.env.REACT_APP_STAGE === "prod"
    ? prod
    : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...config,
};
