import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";



// const Dashboard = lazy(() => import("./components/Dashboard"));
// const Job = lazy(() => import("./components/Job"));
const JobForm = lazy(() => import("./components/job/Job"));
const JobList = lazy(() => import("./components/job/JobList"));
const CandidateList = lazy(() => import("./components/candidate/CandidateList"));
const CandidateForm = lazy(() => import("./components/candidate/Candidate"));
const UserList = lazy(() => import("./components/user/UserList"));
const UserForm = lazy(() => import("./components/user/User"));
const Message = lazy(() => import("./components/message/AppChat"));

const routes = [
    {
        path: "/add-job",
        component: JobForm,
        auth: authRoles.admin
    },
    {
        path: "/edit-job/:id",
        component: JobForm,
        auth: authRoles.admin
    },
    {
        path: "/jobs",
        component: JobList,
        auth: authRoles.admin
    },
    {
        path: "/add-candidate",
        component: CandidateForm,
        auth: authRoles.admin
    },
    {
        path: "/edit-candidate/:id",
        component: CandidateForm,
        auth: authRoles.admin
    },
    {
        path: "/candidates",
        component: CandidateList,
        auth: authRoles.admin
    },
    {
        path: "/al-users",
        component: UserList,
        auth: authRoles.sa
    },
    {
        path: "/add-al-user",
        component: UserForm,
        auth: authRoles.sa
    },
    {
        path: "/edit-al-user/:id",
        component: UserForm,
        auth: authRoles.sa
    },
    {
        path: "/message",
        component: Message
    }
];

export default routes;
