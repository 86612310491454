export const endpoints = {
    session_token: 'user/session',
    master_job_category: '_table/tbl_master_job_category',
    master_constant: '_table/tbl_master_constant',
    master_industry: '_table/tbl_master_industry',
    master_skill: '_table/tbl_master_skill?order=name%20ASC',
    master_skill_type: '_table/tbl_master_skill_type',
    master_message_template: '_table/tbl_master_message_template',
    master_office_location: '_table/tbl_master_office_location?order=name%20ASC',
    master_job_type: '_table/tbl_master_job_type',
    master_certification: '_table/tbl_master_certification?order=name%20ASC',
    master_payment_preference: '_table/tbl_master_payment_preference?order=payment_reference%20ASC',
    master_application_status: '_table/tbl_master_application_status?order=application_status%20ASC',
    save_job: '_func/fn_save_job',
    user_details: '_table/tbl_al_user?filter=firebase_uid=',
    job_list: '_table/tbl_job?related=tbl_master_office_location_by_location_id%2Ctbl_job_category_relation_by_job_id%2Ctbl_al_user_by_created_by&filter=(is_deleted=false)',
    get_job_details: '_table/tbl_job?related=tbl_job_category_relation_by_job_id%2Ctbl_candidate_by_tbl_candidate_job_application&filter=job_id=',
    save_candidate: '_func/fn_save_candidate',
    save_candidate_basicinfo: '_func/fn_save_candidate_basicinfo',
    save_candidate_additionalinfo: '_func/fn_save_candidate_additionalinfo',
    get_candidate_unique_key_list: '_table/tbl_candidate?filter=',
    candidate_job_list: '_table/tbl_job?fields=job_id%2Cjob_title%2Clocation_id%2Cexpiry_on&order=job_title%20ASC&filter=(is_deleted=false)%20AND%20(is_active=true)',
    candidate_inactive_job_list: '_table/tbl_job?fields=job_id%2Cjob_title%2Clocation_id%2Cexpiry_on&order=job_title%20ASC&filter=(is_deleted=false)%20AND%20(is_active=false)',
    save_job_application: '_func/fn_save_job_application',
    candidate_list: '_table/tbl_candidate?related=tbl_master_office_location_by_tbl_candidate_preferred_location%2Ctbl_al_user_by_created_by%2Ctbl_job_by_tbl_candidate_job_application%2Ctbl_candidate_certification_relation_by_candidate_id%2Ctbl_candidate_skill_relation_by_candidate_id&filter=(is_deleted=false)',
    job_table: '_table/tbl_job',
    save_candidate_document: '_func/fn_save_candidate_document',
    get_candidate_details: '_table/tbl_candidate?related=tbl_master_office_location_by_tbl_candidate_preferred_location%2Ctbl_candidate_document_by_candidate_id%2Ctbl_candidate_job_application_by_candidate_id%2Ctbl_candidate_certification_relation_by_candidate_id%2Ctbl_candidate_skill_relation_by_candidate_id&filter=id=',
    save_user: '_func/fn_save_al_user',
    user_list: '_table/tbl_al_user?related=tbl_master_office_location_by_office_location_id&filter=(is_deleted=false)',
    get_user_details: '_table/tbl_al_user?filter=user_id=',
    user_table: '_table/tbl_al_user',
    message_templates: '_table/tbl_master_message_template?filter=is_active=true',
    get_candidate_details_by_firebase_uid: '_table/tbl_candidate?filter=firebase_uid=',
}


